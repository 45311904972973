import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, IconButton, InputAdornment, InputLabel, MenuItem, Select, Stack, TextField, Typography } from '@mui/material';
import type { SelectChangeEvent } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { useState } from 'react';
import theme from '../../../theme/theme';
import { range } from 'lodash';

interface ConfigType {
  headers: {
      Authorization: string;
  };
}

export interface SimpleDialogProps {
  open: boolean;
  onClose: () => void;
  onAdd: (lineItem: { name: string; description: string; quantity: string; unitCost: string, total: string, id: number }) => void;
  postConfig: ConfigType;
}

export default function NewInvoiceLineItemModal(props: SimpleDialogProps) {
  const { onClose, open, onAdd } = props;

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [quantity, setQuantity] = useState('');
  const [unitCost, setUnitCost] = useState('');

  const [errorMessage, setErrorMessage] = useState('');

  const clearForm = () => {
    setName('');
    setDescription('');
    setQuantity('');
    setUnitCost('');
    setErrorMessage('');
  }

  const handleClose = () => {
    clearForm();
    onClose();
  };

  const handleChangeQuantity = (event: SelectChangeEvent) => {
    const value = event.target.value;
    setQuantity(value);
  }

  const handleAdd = () => {
    clearForm()
    onAdd({ name, description, quantity, unitCost, total: total(), id: new Date().getTime() });
    // handleClose();
  };

  const formComplete = () => {
    return Boolean(name) && (Number(quantity) > 0) && (Number(unitCost) > 0);
  };

  const total = () => {
    const amount = quantity ?? 0;
    const cost = unitCost ?? 0;
    return (Number(amount) * Number(cost)).toFixed(2)
  }; 

  return (
    <Dialog onClose={handleClose} open={open} fullWidth maxWidth="lg">
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        Add line item
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid xs={12} lg={6} marginTop={1}>
            <TextField
              label="Item"
              type="text"
              id="item"
              name="item"
              value={name}
              required
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setName(event.target.value);
              }}
              fullWidth
            />
          </Grid>
          <Grid xs={12} lg={6} sx={{ marginTop: { xs: 0, sm: 1 } }}>
            <TextField
              label="Description"
              type="text"
              id="description"
              name="description"
              value={description}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setDescription(event.target.value);
              }}
              fullWidth
            />
          </Grid>
          <Grid xs={12} lg={6}>
            <FormControl fullWidth sx={{ marginY: 1 }}>
              <InputLabel id="quantity-label">
                Quantity*
              </InputLabel>
              <Select
                labelId="quantity-label"
                id="quantity-select"
                value={quantity}
                label='Quantity*'
                onChange={handleChangeQuantity}
              >
                {range(1, 20).map((i) =>
                  <MenuItem key={i} value={i.toString()}>{i}</MenuItem>
                )}
                
              </Select>
            </FormControl>
          </Grid>
          <Grid xs={12} lg={6} sx={{ marginTop: { xs: 0, sm: 1 } }}>
            <TextField
              label="Cost"
              type="text"
              id="cost"
              name="cost"
              required
              value={unitCost}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setUnitCost(event.target.value);
              }}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid xs={12} lg={6} sx={{ marginY: { xs: 2 } }}>
          <Typography>Item Total: ${total()}</Typography>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Stack>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'end' }}>
            <Button variant='outlined' color='primary' onClick={handleClose}>Cancel</Button>
            <Button variant='contained' color='primary' disabled={!formComplete()} onClick={handleAdd} sx={{ marginX: 1 }}>Add</Button>
          </Box>
          <Box>
            {errorMessage.length > 0 && <Typography sx={{ color: theme.palette.error.main}}>{errorMessage}</Typography>}
          </Box>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
