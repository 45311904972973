import React, { useState } from 'react';
import { withCommonTools } from '../../../components/compounds/CommonWrapper/withCommonTools';
import type { WrappedProps } from '../../../components/compounds/CommonWrapper/withCommonTools';

import { Autocomplete, Button, Checkbox, FormControl, FormControlLabel, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField, Typography } from '@mui/material';

import { useNavigate, useSearchParams } from 'react-router-dom';
import toProperCase from '../../../utils/strings';
import { formatDateString } from '../../../utils/dates';
import Dashboard from '../../../components/layouts/Dashboard/Dashboard';
import { format } from 'date-fns';
import axios from 'axios';
import { API_V3_ALLIGATOR_URLS } from '../../../constants/api-urls';
import { useUserCanAccess } from '../../../hooks/userCanAccess';
import FormattedDataGrid from '../../../components/compounds/FormattedDataGrid/FormattedDataGrid';
// import { GridActionsCellItem } from '@mui/x-data-grid';
import type { GridPaginationModel, GridSortModel, GridValueGetterParams } from '@mui/x-data-grid';
import { useFetchAdminListings } from '../../../hooks/fetchListing';
// import EditIcon from '@mui/icons-material/Edit';
import BugReportIcon from '@mui/icons-material/BugReport';
import { ROUTER_URLS } from '../../../constants/router-urls';
import { STATE_DROPDOWN_OPTIONS } from '../../../constants/settings';

interface IProps extends WrappedProps {};

interface SelectOption {
  id: string;
  label: string;
};

function AdminListings({ getConfig, postConfig }: IProps) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const userCanDownload = useUserCanAccess('listingExport');
  const userCanEdit = useUserCanAccess('listingEdit');

  const [listingName, setListingName] = useState<string>('');
  const [hostName, setHostName] = useState<string>('');
  const [addressState, setAddressState] = React.useState<SelectOption | null>(null);
  const [inputAddressState, setInputAddressState] = React.useState('');
  const [minPrice, setMinPrice] = useState('');
  const [maxPrice, setMaxPrice] = useState('');
  const [sellable, setSellable] = useState<boolean>();
  const [exportLoading, setExportLoading] = useState(false);

  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 20,
  });

  const [sortModel, setSortModel] = React.useState<GridSortModel>([
    {
      field: 'createdAt',
      sort: 'desc',
    },
  ]);

  const hostId = searchParams.get('hostId');

  const { data: listingsData, isLoading, refetch } = useFetchAdminListings(getConfig, {
    listing: {
      name: listingName.trim(),
      host_name: hostName.trim(),
      host_id: hostId,
      address_state: addressState?.id ?? '',
      min_price: minPrice ? Number(minPrice) * 100 : null,
      max_price: maxPrice ? Number(maxPrice) * 100 : null,
      // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
      sellable: sellable || null,
    },
    page: paginationModel.page + 1,
    sort: sortModel[0],
  });

  const handleSearch = () => {
    setPaginationModel({ page: 0, pageSize: 20 });
    refetch().catch((e) => { console.log(e) });
  };

  // const handleEditClick = (id: number) => {
  //   const path = generatePath(ROUTER_URLS.admin.listings.show, { id });
  //   navigate(path);
  // }

  const handleNewListing = () => {
    navigate(ROUTER_URLS.admin.listings.new);
  }

  const handleExport = () => {
    if (!userCanDownload) return;

    setExportLoading(true);
    const config: any = { ...postConfig, responseType: 'blob' };
    const params = {
    }
    
    axios.post(API_V3_ALLIGATOR_URLS.admin.listings.export, params, config).then((response) => {
      const csvBlob = new Blob([response.data], { type: 'application/csv' });
      const csvUrl = window.URL.createObjectURL(csvBlob);
      const tempLink = document.createElement('a');
      tempLink.href = csvUrl;
      tempLink.setAttribute(
        'download',
        `listings-export-${format(new Date, 'M-d-yy')}.csv`
      )
      document.body.appendChild(tempLink);
      tempLink.click();
      
      document.body.removeChild(tempLink);
      window.URL.revokeObjectURL(csvUrl);
    }).catch((error) => {
      console.log(error);
    }).finally(() => {
      setExportLoading(false);
    });
  }

  const handleSortModelChange = React.useCallback((newSortModel: GridSortModel) => {
    const copiedSortModel = [...newSortModel];
    setSortModel(copiedSortModel);
  }, []);

  const handlePaginationChange = (newPaginationModel: GridPaginationModel) => {
    setPaginationModel(newPaginationModel);
  }

  const gridHeaders = [
    {
      field: 'published',
      headerName: 'Published',
      minWidth: 100,
      flex: 1,
      editable: false,
      type: 'boolean',
      valueGetter: (params: GridValueGetterParams) => params.row.published,
    },
    {
      field: 'name',
      headerName: 'Name',
      minWidth: 175,
      flex: 1,
      editable: false,
      valueGetter: (params: GridValueGetterParams) => params.row.name,
    },
    {
      field: 'hostName',
      headerName: 'Outfitter',
      width: 150,
      editable: false,
      sortable: false,
      valueGetter: (params: GridValueGetterParams) => params.row.host.name ? toProperCase(params.row.host.name) : '',
    },
    {
      field: 'basePrice',
      headerName: 'Base Price',
      width: 150,
      editable: false,
      sortable: false,
      valueGetter: (params: GridValueGetterParams) => {
        if (!params.row.basePrice)
          return '';
        return `$${(params.row.basePrice / 100).toFixed(2)}`;
      },
    },
    {
      field: 'city',
      headerName: 'City',
      width: 100,
      editable: false,
      sortable: false,
      valueGetter: (params: GridValueGetterParams) => {
        if (!params.row.address?.city)
          return '';
        return params.row.address.city;
      },
    },
    {
      field: 'state',
      headerName: 'State',
      width: 75,
      editable: false,
      sortable: false,
      valueGetter: (params: GridValueGetterParams) => {
        if (!params.row.address?.state)
          return '';
        return params.row.address.state.toUpperCase();
      },
    },
    {
      field: 'salesTeamSellable',
      headerName: 'Sales Confident',
      width: 125,
      editable: false,
      sortable: false,
      type: 'boolean',
    },
    {
      field: 'hostEmail',
      headerName: 'Outfitter Email',
      width: 150,
      editable: false,
      sortable: false,
      valueGetter: (params: GridValueGetterParams) => params.row.host.email,
    },
    {
      field: 'createdAt',
      headerName: 'Created At',
      width: 150,
      editable: false,
      valueGetter: (params: GridValueGetterParams) => `${formatDateString(params.row.createdAt)}`,
    },
    {
      field: 'updatedAt',
      headerName: 'Updated At',
      width: 150,
      editable: false,
      valueGetter: (params: GridValueGetterParams) => `${formatDateString(params.row.updatedAt)}`,
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }: { id: number }) => {
        if (!userCanEdit) {
          return [];
        }
        return [
          <IconButton
            color='primary'
            href={`/admin/listings/${id}`}
            target='_blank'
            rel="noopener"
          >
            <BugReportIcon />
          </IconButton>
          // <GridActionsCellItem
          //   icon={<BugReportIcon />}
          //   label="Edit"
          //   sx={{
          //     color: 'primary.main',
          //   }}
          //   onClick={() => { handleEditClick(id) }}
          //   
          // />,
        ]
      },
    },
  ]

  const ListDisplayGrid = (
    <FormattedDataGrid
      headers={gridHeaders}
      rows={listingsData?.listings ?? []}
      totalRows={listingsData?.page.count ?? 0}
      pagingModel={paginationModel}
      setPagingModel={handlePaginationChange}
      isLoading={isLoading}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: 20,
          },
        },
        sorting: {
          sortModel: [
            { field: 'createdAt', sort: 'desc' }
          ]
        }
      }}
      sortModel={sortModel}
      handleSortModelChange={handleSortModelChange}
    />
  )

  return (
    <Dashboard>
      <Grid container paddingY={2} marginTop={5} alignItems='center'>
        <Grid item xs={6}>
          <Typography variant="h5">Listings</Typography>
        </Grid>
        <Grid item xs={6} display='flex' justifyContent='end'>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={4} sx={{ display: 'flex', alignItems: 'center' }}>
          <TextField
            id="host-email-input"
            label="Name"
            placeholder="Name"
            onChange={(e) => { setListingName(e.target.value) }}
            value={listingName}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} lg={4} sx={{ display: 'flex', alignItems: 'center' }}>
          <TextField
            id="host-name-input"
            label="Outfitter Name"
            placeholder="Outfitter Name"
            onChange={(e) => { setHostName(e.target.value) }}
            value={hostName}
            fullWidth
          />
        </Grid>
        <Grid item lg={3} xs={12}>
          <Autocomplete
            value={addressState}
            onChange={(event: any, newValue: SelectOption | null) => {
              if (newValue?.id) {
                setAddressState(newValue);
              }
              if (newValue === null) {
                setAddressState(null);
              }
            }}
            inputValue={inputAddressState}
            onInputChange={(event, newInputValue) => {
              setInputAddressState(newInputValue);
            }}
            id="controllable-states-demo"
            options={STATE_DROPDOWN_OPTIONS}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            // sx={{ width: 300 }}
            renderInput={(params) => <TextField {...params} label="State" />}
          />
        </Grid>
        <Grid item lg={3} xs={12}>
          <FormControl fullWidth required>
            <InputLabel htmlFor="outlined-adornment-amount">Min price</InputLabel>
            <OutlinedInput
              id="outlined-adornment-amount"
              startAdornment={<InputAdornment position="start">$</InputAdornment>}
              label="Min price"
              value={minPrice}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setMinPrice(event.target.value);
              }}
              required
            />
          </FormControl>
        </Grid>
        <Grid item lg={3} xs={12}>
          <FormControl fullWidth required>
            <InputLabel htmlFor="outlined-adornment-amount">Max price</InputLabel>
            <OutlinedInput
              id="outlined-adornment-amount"
              startAdornment={<InputAdornment position="start">$</InputAdornment>}
              label="Max price"
              value={maxPrice}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setMaxPrice(event.target.value);
              }}
              required
            />
          </FormControl>
        </Grid>
        <Grid item lg={3} xs={12}>
          <FormControlLabel control={<Checkbox
            checked={sellable}
            onChange={(e) => {
              setSellable(e.target.checked);
            }}
            inputProps={{ 'aria-label': 'controlled' }}
          />} label="Sales Confident" />
        </Grid>
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'end' }}>
          <Button
            type="submit"
            variant="contained"
            sx={{ height: '45px', marginY: 3 }}
            onClick={handleSearch}
          >
            Search
          </Button>
          {userCanDownload && <Button
            onClick={handleExport}
            disabled={exportLoading}
            variant='outlined'
            sx={{ height: '45px', marginY: 3, marginLeft: 3, }}
          >
            Download
          </Button>}
          <Button
            type="submit"
            variant="outlined"
            sx={{ height: '45px', marginY: 3, marginLeft: 3 }}
            onClick={handleNewListing}
          >
            + New Listing
          </Button>
        </Grid>
      </Grid>
      { ListDisplayGrid }
    </Dashboard>
  );
}

export default withCommonTools(AdminListings);