import axios from "axios";
import { useQuery } from "react-query";
import { fetchById } from "../api/requests";
import { API_V3_ALLIGATOR_URLS } from "../constants/api-urls";
import type { BookingDetailType, AdminBookingDetailType } from "../types/booking";
import type { PagedResultsType } from "../types/pagedResults";
import type { GridSortItem } from "@mui/x-data-grid";

interface BookingSearchParamsType {
  booking: {
    host_name: string;
    user_name: string;
    status: string;
    start_date: string;
    end_date: string;
    user_id: string | null;
    host_id: string | null;
  }
  page: number;
  sort: GridSortItem
}

export const useFetchBooking = (config: { headers: { Authorization: string } }, bookingId?: string, ) => {
  return useQuery<BookingDetailType>(
    ['BookingInfo', bookingId],
    async () => await fetchById(API_V3_ALLIGATOR_URLS.booking.show, bookingId, config),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
    }
  );
};

export const useFetchAdminBooking = (config: { headers: { Authorization: string } }, bookingId?: string, ) => {
  return useQuery<AdminBookingDetailType>(
    ['BookingInfo', bookingId],
    async () => await fetchById(API_V3_ALLIGATOR_URLS.admin.bookings.show, bookingId, config),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
    }
  );
};

export const useFetchAdminBookings = (config: { headers: { Authorization: string } }, params: BookingSearchParamsType) => {
  return useQuery<{bookings: BookingDetailType[], page: PagedResultsType}>(
    ['AdminBookingsInfo', params.page, params.sort?.field, params.sort?.sort],
    async () => {
      const response = await axios.post(API_V3_ALLIGATOR_URLS.admin.bookings.search, params, config);

      if (response.status !== 200) {
        if (response.status === 401) {
          throw new Error('error');
        }
        throw new Error('Network response was not ok');
      }
      return response.data;
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
    }
  );
};