import React, { useEffect, useState } from 'react';
import { Box, Button, FormControl, FormHelperText, Grid, MenuItem, Select, Typography } from '@mui/material';
import type { SelectChangeEvent } from '@mui/material';
import DashboardLayout from '../../components/layouts/Dashboard/Dashboard';
import { useFetchListings } from '../../hooks/fetchListing';
import type { ListingDetailType } from '../../types/listing';
import { API_V3_ALLIGATOR_URLS } from '../../constants/api-urls';
import { calendarDatesToStringDates, stringDatesToCalendarDates } from '../../utils/arrays';
import BigCalendar from '../../components/compounds/BigCalendar/BigCalendar';
import { withCommonTools } from '../../components/compounds/CommonWrapper/withCommonTools';
import type { WrappedProps } from '../../components/compounds/CommonWrapper/withCommonTools';
import { fetchById } from '../../api/requests';
import type { EventInput } from '@fullcalendar/core';
import axios from 'axios';
import LoadingSpinner from '../../components/atoms/LoadingSpinner/LoadingSpinner';

interface IProps extends WrappedProps {};

function CalendarAvailability({ getConfig, postConfig, getCurrentUserId }: IProps) {

  const [selectedListingId, setSelectedListingId] = useState<string>('');
  const [calendarAvailableDates, setCalendarAvailableDates] = useState<EventInput[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const { data: listingRecords, isLoading: isListLoading } = useFetchListings(postConfig);

  useEffect(() => {
    if (selectedListingId) {
      setIsLoading(true)
      fetchById(API_V3_ALLIGATOR_URLS.listing.show, selectedListingId, getConfig).then((res) => {
        const mappedDates = stringDatesToCalendarDates(res.availableDates, '#18737F');
        setCalendarAvailableDates(mappedDates)
        setIsLoading(false)
      }).catch((e) => {
        console.log(e);
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps  
  }, [selectedListingId])

  const handleSave = () => {
    setIsLoading(true);
    
    const availableDates = calendarDatesToStringDates(calendarAvailableDates);

    const params = {
      listing: {
        available_dates: availableDates,
      }
    };

    axios.patch(`${API_V3_ALLIGATOR_URLS.listing.update}${selectedListingId ?? ''}`, params, postConfig).then((response) => {
      const newListingData: ListingDetailType = response.data;
      if (newListingData.id) {
        setIsLoading(false);
      } else {
        console.log('error failed to save');
      }
    }).catch((error) => {
      console.log(error);
    });
  };

  const handleListingSelectChange = (event: SelectChangeEvent) => {
    setSelectedListingId(event.target.value);
  };

  return (
    <DashboardLayout loading={isListLoading}>
      <Grid container marginBottom={10} spacing={1}>
        <Grid item xs={12}>
          <Typography variant='h4' sx={{ textAlign: 'center' }}>Set Open Dates</Typography>
        </Grid>
        <Grid item xs={12} sm={6} sx={{ marginY: 2 }}>
          <FormControl fullWidth>
            <Select
              labelId="listing-label"
              id="listing-select"
              value={selectedListingId}
              onChange={handleListingSelectChange}
              fullWidth
              placeholder='Select Listing'
              displayEmpty
            >
              <MenuItem value="">
                Select Listing
              </MenuItem>
              {listingRecords && listingRecords.length > 0 && listingRecords.map((listing) => (
                <MenuItem key={listing.id} value={listing.id.toString()}>{listing.name}</MenuItem>
              ))}
            </Select>
            <FormHelperText>Select a listing to set your open dates</FormHelperText>
          </FormControl>
        </Grid>
        {isLoading ? 
          <Grid item xs={12}>
            <LoadingSpinner />
          </Grid>
        : <> 
          {selectedListingId ? (
            <>
              <Grid item xs={12}>
                <BigCalendar
                  dates={calendarAvailableDates}
                  setDates={setCalendarAvailableDates}
                />
              </Grid>
              <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                <Button variant='contained' color='secondary' sx={{ width: 250, borderRadius: '6px', marginX: 2 }} disabled={isLoading} onClick={handleSave}>Save</Button>
              </Grid>
              <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginTop: 5 }}>
                <Box sx={{ height: '30px', width: '30px', backgroundColor: '#18737F', borderRadius: 1, marginRight: 1 }} />
                <Typography variant='h6' textAlign='center'>Open dates are marked blue</Typography>
              </Grid>
            </>
          ) : 
            <>
              <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                
              </Grid>
              <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginTop: 8 }}>
                <Box sx={{ height: '30px', width: '30px', backgroundColor: '#18737F', borderRadius: 1, marginRight: 1 }} />
                <Typography variant='h6' textAlign='center'>Open dates are marked blue</Typography>
              </Grid>
            </>
          }
        </>}
      </Grid>
    </DashboardLayout>
  );
}

export default withCommonTools(CalendarAvailability);
