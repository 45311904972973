import * as React from 'react';
import Box from '@mui/material/Box';
import type { GridColDef, GridSortModel, GridColumnMenuProps, GridInitialState } from '@mui/x-data-grid';
import { DataGrid, GridColumnMenu } from '@mui/x-data-grid';

interface IProps {
  headers: GridColDef[];
  rows: any[];
  totalRows: number;
  pagingModel: {
    page: number;
    pageSize: number;
  }
  setPagingModel: (pagingModal: { page: number, pageSize: number }) => void;
  isLoading: boolean;
  initialState: GridInitialState;
  sortModel: GridSortModel;
  handleSortModelChange: (sortModel: GridSortModel) => void
}

function CustomColumnMenu(props: GridColumnMenuProps) {
  return (
    <GridColumnMenu
      {...props}
      slots={{
        // Hide `columnMenuColumnsItem`
        columnMenuFilterItem: null,
      }}
    />
  );
}

export default function FormattedDataGrid({ headers, rows, totalRows, pagingModel, setPagingModel, isLoading, initialState, sortModel, handleSortModelChange }: IProps) {
  return (
    <Box sx={{ width: '100%' }}>
      <DataGrid
        rows={rows}
        columns={headers}
        initialState={initialState}
        slots={{ columnMenu: CustomColumnMenu }}
        checkboxSelection={false}
        disableRowSelectionOnClick
        paginationMode='server'
        pageSizeOptions={[20]}
        rowCount={totalRows}
        pagination
        paginationModel={pagingModel}
        onPaginationModelChange={setPagingModel}
        loading={isLoading}
        sortingMode='server'
        sortModel={sortModel}
        onSortModelChange={handleSortModelChange}
        sx={{
          '.MuiTablePagination-spacer': {
            flex: 'none !important'
          },
          '.MuiDataGrid-virtualScroller': {
            minHeight: 100
          }
        }}
      />
    </Box>
  );
}