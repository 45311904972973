import React from 'react';
import { Box, Grid, Paper, Typography } from '@mui/material';
import formatCurrency from '../../../utils/currency';

interface IProps {
  listingBasePrice: number;
  surcharge: number;
  usageFee: number;
};

function ListingPricingSummary({ listingBasePrice, surcharge, usageFee }: IProps) {  
  const surchargeMultiplier = 1 + (surcharge / 100.0);
  const usageMultiplier = 1 - (usageFee / 100.0);
  const hostRevenue = listingBasePrice * usageMultiplier;
  const listingTotalPrice = listingBasePrice * surchargeMultiplier;

  return (
    <Paper elevation={2}>
      <Box width='100%' sx={{ display: 'flex', justifyContent: 'center', justifyItems: 'center', borderBottom: 1, borderColor: 'divider' }}>
        <Typography
          variant='body1'
          component='p'
          align='center'
          sx={{
            mt: 3,
            mb: 3,
            display: 'flex',
            justifyContent: 'center',
            justifyItems: 'center',
            width: '75%',
            alignItems: 'center',
            fontWeight: 600,
          }}
        >
          Pricing Breakdown
        </Typography>
      </Box>
      <Grid container direction='row' spacing={2} padding={2}>
        <Grid item xs={6}>
          <Typography
              variant='body1'
              component='p'
              align='center'
              sx={{
                // mt: 3,
                // mb: 3,
                display: 'flex',
                // justifyContent: 'center',
                // justifyItems: 'center',
                // width: '75%',
                // alignItems: 'center',
              }}
            >
              Listing Base Price:
            </Typography>
          </Grid>
          <Grid item xs={6}>
          <Typography
              variant='body1'
              component='p'
              align='center'
              sx={{
                // mt: 3,
                // mb: 3,
                display: 'flex',
                justifyContent: 'center',
                justifyItems: 'center',
                // width: '75%',
                // alignItems: 'center',
              }}
            >
              $ { formatCurrency(listingBasePrice) }
            </Typography>
          </Grid>
        </Grid>
      <Grid container direction='row' spacing={2} padding={2}>
        <Grid item xs={6}>
          <Typography
              variant='body1'
              component='p'
              align='center'
              sx={{
                // mt: 3,
                // mb: 3,
                display: 'flex',
                // justifyContent: 'center',
                // justifyItems: 'center',
                // width: '75%',
                // alignItems: 'center',
              }}
            >
              Outfitter Revenue:
            </Typography>
          </Grid>
          <Grid item xs={6}>
          <Typography
              variant='body1'
              component='p'
              align='center'
              sx={{
                // mt: 3,
                // mb: 3,
                display: 'flex',
                justifyContent: 'center',
                justifyItems: 'center',
                // width: '75%',
                // alignItems: 'center',
              }}
            >
              $ { formatCurrency(hostRevenue) }
            </Typography>
          </Grid>
      </Grid>
      <Grid container direction='row' spacing={2} padding={2} paddingBottom={10}>
        <Grid item xs={6}>
          <Typography
              variant='body1'
              component='p'
              align='center'
              sx={{
                // mt: 3,
                // mb: 3,
                display: 'flex',
                // justifyContent: 'center',
                // justifyItems: 'center',
                // width: '75%',
                // alignItems: 'center',
              }}
            >
              Listing Total Price:
            </Typography>
          </Grid>
          <Grid item xs={6}>
          <Typography
              variant='body1'
              component='p'
              align='center'
              sx={{
                // mt: 3,
                // mb: 3,
                display: 'flex',
                justifyContent: 'center',
                justifyItems: 'center',
                // width: '75%',
                // alignItems: 'center',
              }}
            >
              $ { formatCurrency(listingTotalPrice) }
            </Typography>
          </Grid>
      </Grid>
    </Paper>
  );
};

export default ListingPricingSummary;