import React, { useState } from 'react';
import { withCommonTools } from '../../components/compounds/CommonWrapper/withCommonTools';
import type { WrappedProps } from '../../components/compounds/CommonWrapper/withCommonTools';
import DashboardLayout from '../../components/layouts/Dashboard/Dashboard';
import { Box, Button, FormControl, InputLabel, MenuItem, Select, Stack, TextField, Typography } from '@mui/material';
import type { SelectChangeEvent } from '@mui/material';

import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { API_V3_ALLIGATOR_URLS } from '../../constants/api-urls';
import { ROUTER_URLS } from '../../constants/router-urls';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
// import { dateFromISOString, updateTimeToCurrent } from '../../utils/dates';
import { updateTimeToCurrent } from '../../utils/dates';

// import toProperCase from '../../utils/strings';
import { useFetchListings } from '../../hooks/fetchListing';
import { range } from 'lodash';
import { PhoneNumber } from '../../components/atoms/PhoneInput/PhoneInput';
import type { E164Number } from '../../components/atoms/PhoneInput/PhoneInput';

interface IProps extends WrappedProps {};

const BOOKING_STATUSES = [
  { key: 'approved', label: 'Upcoming' },
  { key: 'completed', label: 'Completed' },
  { key: 'cancelled', label: 'Cancelled' }
];

function NewBooking({ postConfig }: IProps) {
  const navigate = useNavigate();

  const [selectedListingId, setSelectedListingId] = useState<string>('');

  const { data: listingRecords, isLoading } = useFetchListings(postConfig);

  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  const [status, setStatus] = useState('approved');
  const [numberOfGuests, setNumberOfGuests] = useState('');

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');

  const [isSaving, setIsSaving] = useState(false);

  const handleBack = () => {
    navigate(ROUTER_URLS.calendar.index);
  }

  const handleSubmit = (): void => {
    setIsSaving(true);

    const params = {
      trip: {
        listing_id: selectedListingId,
        start_date: startDate ? startDate.toISOString() : '',
        end_date: endDate ? endDate.toISOString() : '',
        status: status.toLowerCase(),
        number_of_guests: Number(numberOfGuests),
        contact_attributes: {
          first_name: firstName,
          last_name: lastName,
          email_address: emailAddress,
          phone_number: phoneNumber,
        },
        source: 'host_added',
      }
    }
    axios.post(`${API_V3_ALLIGATOR_URLS.booking.create}`, params, postConfig).then(() => {
      navigate(ROUTER_URLS.calendar.index);
    }).catch(() => {
      setIsSaving(false);
    });
  };

  const handleStatusChange = (event: SelectChangeEvent) => {
    setStatus(event.target.value);
  };

  const handlePhoneNumberChange = (event?: E164Number | undefined) => {
    setPhoneNumber(event ?? "");
  }

  const handleListingSelectChange = (event: SelectChangeEvent) => {
    setSelectedListingId(event.target.value);
  };

  const handleChangeNumberOfGuests = (event: SelectChangeEvent) => {
    const value = event.target.value;
    setNumberOfGuests(value);
  }

  const requiredFields = (): boolean => {
    return Boolean(selectedListingId) &&
           startDate !== undefined &&
           endDate !== undefined &&
           Boolean(status) &&
           Boolean(numberOfGuests) &&
           Boolean(firstName) &&
           Boolean(lastName);
  }

  return (
    <DashboardLayout loading={isSaving}>
      <Stack spacing={2} sx={{ maxWidth: '1000px' }}>
        <Typography component="h1" variant="h5">
          Add new trip
        </Typography>
        <Box>
          { !isLoading &&
            <FormControl fullWidth>
              <InputLabel id="booking-status-label">Select Listing*</InputLabel>
              <Select
                labelId="listing-label"
                id="listing-select"
                value={selectedListingId}
                onChange={handleListingSelectChange}
                fullWidth
                label='Select Listing*'
                placeholder='Select Listing*'
              >
                {listingRecords && listingRecords.length > 0 && listingRecords.map((listing) => (
                  <MenuItem key={listing.id} value={listing.id.toString()}>{listing.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          }
        </Box>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Box>
            <DatePicker
              label="Start Date*"
              value={startDate}
              minDate={undefined}
              maxDate={endDate}
              onChange={(newValue) => {
                updateTimeToCurrent(newValue)
                setStartDate(newValue as (Date | undefined))
              }}
              sx={{ width: '100%' }}
            />
          </Box>
          <Box>
            <DatePicker
              label="End Date*"
              value={endDate}
              minDate={startDate}
              maxDate={undefined}
              onChange={(newValue) => {
                updateTimeToCurrent(newValue)
                setEndDate(newValue as (Date | undefined))
              }}
              sx={{ width: '100%' }}
            />
          </Box>
        </LocalizationProvider>
        <Box>
          <FormControl fullWidth>
            <InputLabel id="booking-status-label">Status*</InputLabel>
            <Select
              required
              labelId="status-label"
              id="status-select"
              value={status}
              label="Status"
              onChange={handleStatusChange}
              fullWidth
            >
              { BOOKING_STATUSES.map((status) => (
                <MenuItem key={status.key} value={status.key}>{status.label}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box>
          <FormControl fullWidth sx={{ marginY: 1 }}>
            <InputLabel id="number-of-guests-label">
              Number of Hunters*
            </InputLabel>
            <Select
              labelId="number-of-guests-label"
              id="number-of-guests-select"
              value={numberOfGuests}
              label='Number of Hunters*'
              onChange={handleChangeNumberOfGuests}
            >
              {range(1, 20).map((i) =>
                <MenuItem key={i} value={i.toString()}>{i}</MenuItem>
              )}
              
            </Select>
          </FormControl>
        </Box>
        <Box>
          <Typography variant="h6">
            Trip leader details
          </Typography>
        </Box>
        <Box>
          <TextField
            label="First name"
            type="text"
            id="first-name"
            name="first-name"
            autoComplete="given-name"
            value={firstName}
            required
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setFirstName(event.target.value);
            }}
            fullWidth
          />
        </Box>
        <Box>
          <TextField
            label="Last name"
            type="text"
            id="last-name"
            name="last-name"
            required
            autoComplete="family-name"
            value={lastName}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setLastName(event.target.value);
            }}
            fullWidth
          />
        </Box>
        <Box>
          <TextField
            type="email"
            id="email-address"
            name="email-address"
            autoComplete="email"
            label="Email Address"
            value={emailAddress}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setEmailAddress(event.target.value);
            }}
            fullWidth
          />
        </Box>
        <Box>
          <PhoneNumber
            value={phoneNumber}
            onChange={handlePhoneNumberChange}
            label="Phone Number"
            helperText=""
          />
        </Box>
        <Box display='flex' flexDirection='row' justifyContent='flex-end'>
          <Button variant="outlined" onClick={handleBack}>Cancel</Button>
          <Button variant="contained" onClick={handleSubmit} sx={{ mx: 2 }} disabled={isSaving || !requiredFields()}>Save</Button>
        </Box>
      </Stack>
    </DashboardLayout>  
  );
}

export default withCommonTools(NewBooking);