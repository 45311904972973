import { Box, Button, Dialog, DialogContent, DialogTitle, IconButton, Stack} from '@mui/material';
import { useFetchBooking } from '../../../hooks/fetchBooking';
import LoadingSpinner from '../../atoms/LoadingSpinner/LoadingSpinner';
import BookingListingCard from '../BookingCard/BookingListingCard';
import BookingPayoutCard from '../BookingCard/BookingPayoutCard';
import BookingUserCard from '../BookingCard/BookingUserCard';
import CloseIcon from '@mui/icons-material/Close';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import axios from 'axios';
import { API_V3_ALLIGATOR_URLS } from '../../../constants/api-urls';
import { generatePath, useNavigate } from 'react-router-dom';
import { ROUTER_URLS } from '../../../constants/router-urls';

interface ConfigType {
  headers: {
      Authorization: string;
  };
}

export interface SimpleDialogProps {
  open: boolean;
  onClose: () => void;
  getConfig: ConfigType;
  tripId?: string;
}



function LoadingModalContent() {
  return (
    <>
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        Hunt Details
      </DialogTitle>
      <DialogContent dividers sx={{ paddingBottom: 20 }}>
        <LoadingSpinner />
      </DialogContent>
    </>
  );
}

function TripDetailsModalContent({ tripId, getConfig, onClose }: { getConfig: ConfigType, tripId: string, onClose: () => void; }) {
  const navigate = useNavigate();
  const { data: bookingRecord } = useFetchBooking(getConfig, tripId);

  if (!bookingRecord) {
    return <LoadingModalContent />
  }

  const bookingRequested = ['requested'].includes(bookingRecord.status.toLowerCase());
  const firstPaymentCompleted = bookingRecord.userPayments && bookingRecord.userPayments.filter((payment) => payment.status === 'completed').length > 0;
  
  // const hasStripeConnected = Boolean(userRecord?.connectAccountSetup);

  const handleDelete = () => {
    const path = API_V3_ALLIGATOR_URLS.booking.destroy.replace(':id', tripId)
    axios.delete(path, getConfig).then((res) => {
      onClose();
    }).catch((e) => {
      console.log(e);
    })
  };

  const handleEdit = () => {
    const path = generatePath(ROUTER_URLS.bookings.edit, { id: tripId });
    navigate(path);
  }

  return (
    <>
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        {bookingRecord && bookingRecord.source === 'host_added' && <IconButton onClick={handleEdit}>
          <EditIcon fontSize="small" color='primary' sx={{ marginRight: 1 }} />
        </IconButton>}
        Hunt Details
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <Stack sx={{ margin: { xs: 0, md: 3 }, marginBottom: 3 }} spacing={3}>
          <BookingListingCard booking={bookingRecord} />
          {bookingRecord.user && <BookingUserCard user={bookingRecord.user} chatId={bookingRecord.chatId} status={bookingRecord.status} />}
          {!bookingRequested && firstPaymentCompleted && <BookingPayoutCard booking={bookingRecord} />}
          {
            // <BookingFeeDetailsCard
            //   booking={bookingRecord}
            //   confirmBooking={approveBooking}
            //   denyBooking={denyBooking}
            //   hasStripeConnected={hasStripeConnected}
            //   handleStripeClick={handleStripeConnectClick}
            //   userId={userId}
            // />
            }
            {bookingRecord.source === 'host_added' && <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
              <Button startIcon={<DeleteForeverIcon />} color='error' variant='contained' sx={{ width: 200 }} onClick={handleDelete}>Delete</Button>
            </Box>}
        </Stack>
      </DialogContent>
    </>
  )
}


export default function CalendarTripModal(props: SimpleDialogProps) {
  const { onClose, open, getConfig, tripId } = props;

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog onClose={handleClose} open={open} fullWidth maxWidth="md">
      { tripId && <TripDetailsModalContent getConfig={getConfig} tripId={tripId} onClose={handleClose} /> }
    </Dialog>
  );
}
