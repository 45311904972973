import React from 'react';
import DashboardLayout from '../../../components/layouts/Dashboard/Dashboard';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { withCommonTools } from '../../../components/compounds/CommonWrapper/withCommonTools';
import type { WrappedProps } from '../../../components/compounds/CommonWrapper/withCommonTools';

import { Box, Button, Grid, ImageList, ImageListItem, Link, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import RecordDetailHeader from '../../../components/atoms/RecordDetailHeader/RecordDetailHeader';
import { ROUTER_URLS } from '../../../constants/router-urls';
import RecordDetailAttribute from '../../../components/atoms/RecordDetailAttribute/RecordDetailAttribute';

import { useFetchAdminListing } from '../../../hooks/fetchListing';
import RecordDetailRichText from '../../../components/atoms/RecordDetailRichText/RecordDetailRichText';

import formatCurrency from '../../../utils/currency';

// import EditIcon from '@mui/icons-material/Edit';
import CircularProgressWithLabel from '../../../components/compounds/CircularProgressWithLabel/CircularProgressWithLabel';
import axios from 'axios';
import { API_V3_ALLIGATOR_URLS } from '../../../constants/api-urls';
import { formatDateString } from '../../../utils/dates';


interface IProps extends WrappedProps {};

function AdminListing({ getConfig, postConfig }: IProps) {
  const { id: listingId } = useParams();
  const navigate = useNavigate();

  const { data: listingRecord, isLoading, refetch, isRefetching } = useFetchAdminListing(getConfig, listingId ?? '');

  const createEditPath = (editPath: string) => {
    return generatePath(editPath, { id: listingId });
  };

  // const navigateToEditAdditionalCharge = (e: React.MouseEvent<HTMLElement>, id: number) => {
  //   e.stopPropagation();
  //   const path = generatePath(ROUTER_URLS.admin.payouts.edit, { id });
  //   navigate(path);
  // };

  const deleteListing = () => {
    if (listingId) {
      axios.patch(`${API_V3_ALLIGATOR_URLS.listing.update}${listingId}`, { listing: { host_hidden: true } }, postConfig).then(() => {
        navigate(ROUTER_URLS.admin.listings.index);
      }).catch((error) => {
        console.log(error);
      })
    }
  }

  const publishListing = () => {
    if (listingId && listingRecord) {
      axios.patch(`${API_V3_ALLIGATOR_URLS.listing.update}${listingId}`, { listing: { published: !listingRecord.published } }, postConfig).then(() => {
        refetch().catch((e) => { console.log(e) });
      }).catch((error) => {
        console.log(error);
      })
    }
  }

  const navigateToListing = (id: string) => {
    const path = generatePath(ROUTER_URLS.listings.show, { id });
    navigate(path);
  };

  const duplicateListing = () => {
    const params = {
      id: listingId,
    }
    axios.post(API_V3_ALLIGATOR_URLS.listing.duplicate, params, postConfig).then((response) => {
      const newListing = response.data;
      if (newListing?.id) {
        navigateToListing(newListing.id);
      }
    }).catch((error) => {
      console.log(error);
    })
  }

  if (!listingRecord) {
    return <DashboardLayout loading />;
  }

  const progress = (Object.values(listingRecord).filter((v) => v && v.toString().length > 0).length / Object.keys(listingRecord).length) * 100;

  return (
    <DashboardLayout loading={isLoading || isRefetching}>
      <Stack>
        <RecordDetailHeader
          title="Listing Details"
          description="General information"
          editPath={createEditPath(ROUTER_URLS.admin.listings.edit)}
        >
          <Stack sx={{ width: 120, display: 'flex', alignItems: 'center' }}>
            <Typography variant='body1' fontSize={12}>% Complete</Typography>
            <Box>
              <CircularProgressWithLabel variant="determinate" value={progress}/>
            </Box>
          </Stack>
          <Button variant='contained' color='warning' onClick={publishListing}>
            { listingRecord.published ? 'Unpublish' : 'Publish' }
          </Button>

        </RecordDetailHeader>
        <Box sx={{ marginX: 5 }}>
          <RecordDetailAttribute attribute="Name" value={listingRecord.name} />
          <RecordDetailAttribute attribute="Category" value={listingRecord.category} />
          <RecordDetailAttribute attribute="Short Description" value={listingRecord.shortDescription} />
          <RecordDetailAttribute attribute="Keywords" value={listingRecord.keywords?.join(', ')} />

          <Grid container direction='row' marginY={2}>
            <Grid item xs={12} lg={3}>
              <Typography variant='body1' sx={{ fontWeight: 600 }}>Marketplace Link</Typography>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Link href={`https://www.venku.com/listing/s/${listingRecord.slug}`} target='_blank' rel="noopener">
                Marketplace Link
              </Link>
            </Grid>
          </Grid>
          <Grid container direction='row' marginY={2}>
            <Grid item xs={12} lg={3}>
              <Typography variant='body1' sx={{ fontWeight: 600 }}>Hunt Pay Link</Typography>
            </Grid>
            <Grid item xs={12} lg={6}>
              {listingRecord.bookNowSlug ? <Link href={`https://www.huntpay.com/listing/s/${listingRecord.bookNowSlug}`} target='_blank' rel="noopener">
                Hunt Pay Link
              </Link> : 'N/A'}
            </Grid>
          </Grid>
        </Box>
        <RecordDetailHeader
          title="Address"
          description=""
          editPath={createEditPath(ROUTER_URLS.admin.listings.editAddress)}
        />
        <Box sx={{ marginX: 5 }}>
          <RecordDetailAttribute attribute="Address One" value={listingRecord.address?.line_one} />
          <RecordDetailAttribute attribute="Address Two" value={listingRecord.address?.line_two} />
          <RecordDetailAttribute attribute="City" value={listingRecord.address?.city} />
          <RecordDetailAttribute attribute="State" value={listingRecord.address?.state} />
          <RecordDetailAttribute attribute="Zip" value={listingRecord.address?.zip_code} />
          <RecordDetailAttribute attribute="Country" value={listingRecord.address?.country} />
          <RecordDetailAttribute attribute="Latitude" value={listingRecord.address?.latitude ?? ''} />
          <RecordDetailAttribute attribute="Longitude" value={listingRecord.address?.longitude ?? ''} />
        </Box>

        <RecordDetailHeader
          title="Details"
          description=""
          editPath={createEditPath(ROUTER_URLS.admin.listings.editDetails)}
        />
        <Box sx={{ marginX: 5 }}>
          <RecordDetailAttribute attribute="Amenities" value={listingRecord.amenities?.join(', ') ?? 'N/A'} />
          <RecordDetailAttribute attribute="Gear Requirements" value={listingRecord.extras?.join(', ') ?? 'N/A'} />
          <RecordDetailRichText attribute="Description" value={listingRecord.description} />
          <RecordDetailRichText attribute="House Rules" value={listingRecord.houseRules } />
          <RecordDetailRichText attribute="Reviews" value={listingRecord.testimonials } />
          <RecordDetailAttribute attribute="Handicap Accessible" value={listingRecord.handicappedAccessible ? 'Y' : 'N' } />
          <RecordDetailAttribute attribute="Kid Friendly" value={listingRecord.kidFriendly ? 'Y' : 'N' } />
          <RecordDetailAttribute attribute="Dog Friendly" value={listingRecord.petFriendly ? 'Y' : 'N' } />
          <RecordDetailAttribute attribute="Invasive" value={listingRecord.invasive ? 'Y' : 'N' } />
          <RecordDetailAttribute attribute="Acreage" value={listingRecord.acreage?.toString()} />
          <RecordDetailAttribute attribute="Lodging Type" value={listingRecord.lodgingType } />
          <RecordDetailAttribute attribute="Property Type" value={listingRecord.propertyType } />
          <RecordDetailAttribute attribute="Guided Type" value={listingRecord.guidedType } />
        </Box>

        <RecordDetailHeader
          title="Pricing"
          description=""
          editPath={createEditPath(ROUTER_URLS.admin.listings.editPricing)}
        />
        <Box sx={{ marginX: 5 }}>
          <RecordDetailAttribute attribute="Base Price" value={`$${formatCurrency(listingRecord.basePrice / 100)}`} />
          <RecordDetailAttribute attribute="Lisitng Unit" value={listingRecord.listingUnit} />
          <RecordDetailAttribute attribute="Per Person" value={listingRecord.pricePerPerson ? 'Y' : 'N'} />
          <Typography variant='body1' sx={{ fontWeight: 600 }}>Additional Charges:</Typography>
          {listingRecord.additionalCosts && listingRecord.additionalCosts.length > 0 ? <TableContainer component={Paper}>
            <Table size='small' sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">Description</TableCell>
                  <TableCell align="left">Price</TableCell>
                  <TableCell align="left">Per Person</TableCell>
                  <TableCell align="left">Per Day</TableCell>
                  <TableCell align="left">Per Night</TableCell>
                  <TableCell align="left">Optional</TableCell>
                  <TableCell align="left">Min Hunters</TableCell>
                  <TableCell align="left">Max Hunters</TableCell>
                  <TableCell align="left">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {listingRecord.additionalCosts?.map((row) => (
                  <TableRow
                    key={row.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    hover={true}
                  >
                    <TableCell scope="row" align="left">{row.description}</TableCell>
                    <TableCell align="left">{`$${formatCurrency(row.price / 100)}`}</TableCell>
                    <TableCell align="left">{row.perPerson ? 'Y' : 'N'}</TableCell>
                    <TableCell align="left">{row.perDay ? 'Y' : 'N'}</TableCell>
                    <TableCell align="left">{row.perNight ? 'Y' : 'N'}</TableCell>
                    <TableCell align="left">{row.isOptional ? 'Y' : 'N'}</TableCell>
                    <TableCell align="left">{row.minValue}</TableCell>
                    <TableCell align="left">{row.maxValue}</TableCell>
                    <TableCell align="left" sx={{ minWidth: 75 }}>
                      {
                      // <IconButton color='primary' sx={{ height: '20px' }} onClick={(e) => { navigateToEditAdditionalCharge(e, row.id as unknown as number) }}>
                      //   <EditIcon />
                      // </IconButton>
                      }
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer> :
          <Typography variant='body1'>No additional charges</Typography>}
        </Box>
        <RecordDetailHeader
          title="Photos"
          description=""
          editPath={createEditPath(ROUTER_URLS.admin.listings.editPhotos)}
        />
        <Box sx={{ marginX: 5 }}>
          {listingRecord.mediaListings && listingRecord.mediaListings.length > 0 ? <ImageList sx={{ height: 450 }} cols={3} rowHeight={164}>
            { listingRecord.mediaListings.map((item) => (
              <ImageListItem key={item.id}>
                <img
                  src={item.url}
                  loading="lazy"
                />
              </ImageListItem>
            ))}
          </ImageList> :
          <Typography variant='body1'>No photos</Typography>}
        </Box>

        <RecordDetailHeader
          title="Availability"
          description=""
          editPath={createEditPath(ROUTER_URLS.admin.listings.editAvailability)}
        />
        <Box sx={{ marginX: 5 }}>
          <RecordDetailAttribute attribute="Check In Time" value={listingRecord.checkInTime} />
          <RecordDetailAttribute attribute="Check Out Time" value={listingRecord.checkOutTime} />
          <RecordDetailAttribute attribute="Limit Number of Hunters" value={listingRecord.enforceAccomodationRange ? 'Y' : 'N'} />
          <RecordDetailAttribute attribute="Min Hunters" value={listingRecord.accommodateMin?.toString()} />
          <RecordDetailAttribute attribute="Max Hunters" value={listingRecord.accommodateMax?.toString()} />
          <RecordDetailAttribute attribute="Contact Phone Number" value={listingRecord.contactNumber} />
          <RecordDetailAttribute attribute="Available Dates" value={listingRecord.availableDates.map((date) => formatDateString(date)).join(', ')} />
        </Box>

        <RecordDetailHeader
          title="Admin/Sales Fields"
          description=""
          editPath={createEditPath(ROUTER_URLS.admin.listings.editAdminFields)}
        />
        <Box sx={{ marginX: 5 }}>
          <RecordDetailAttribute attribute="Published" value={listingRecord.published ? 'Y' : 'N'} />
          <RecordDetailAttribute attribute="Sales Confident" value={listingRecord.salesTeamSellable ? 'Y' : 'N'} />
          <RecordDetailAttribute attribute="Sales Score" value={listingRecord.salesTeamScore?.toString()} />
          { 
            // come back and add available dates late
          }
        </Box>

        <Box sx={{ borderBottom: 1, borderColor: 'divider', paddingY: 2, marginX: 5, marginTop: 5 }}>
          <Grid container direction='row'>
            <Grid item lg={12} xs={12}>
              <Typography variant="h5" marginY={1}>DANGER - Unreversable admin functions</Typography>
              <Typography variant="body1">Please confirm you know the result of each action prior to clicking</Typography>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ marginX: 5 }}>
          <Grid container direction='row' marginY={4}>
            <Grid item xs={12} lg={6}>
              <Typography variant='body1'>Duplicates the listing for the host</Typography>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Button variant='contained' color='secondary' onClick={duplicateListing}>
                Duplicate
              </Button>
            </Grid>
          </Grid>
          <Grid container direction='row' marginY={4}>
            <Grid item xs={12} lg={6}>
              <Typography variant='body1'>Hides the listing from the huntpay dashboard</Typography>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Button variant='contained' color='error' onClick={deleteListing}>
                Delete
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Stack>
    </DashboardLayout>
  );
}

export default withCommonTools(AdminListing);
