import React, { useEffect, useState } from 'react';
import { withCommonTools } from '../../../components/compounds/CommonWrapper/withCommonTools';
import type { WrappedProps } from '../../../components/compounds/CommonWrapper/withCommonTools';
import DashboardLayout from '../../../components/layouts/Dashboard/Dashboard';
import { Box, Button, Container, FormControl, InputLabel, MenuItem, Select, Stack, Typography } from '@mui/material';
import type { SelectChangeEvent } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { API_V3_ALLIGATOR_URLS } from '../../../constants/api-urls';
import { ROUTER_URLS } from '../../../constants/router-urls';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { dateFromISOString, updateTimeToCurrent } from '../../../utils/dates';
import toProperCase from '../../../utils/strings';
import { useFetchAdminBooking } from '../../../hooks/fetchBooking';

interface IProps extends WrappedProps {};

const BOOKING_STATUSES = ['All', 'Requested', 'Approved', 'Denied', 'Completed', 'Error', 'Cancelled', 'Imported']

function AdminEditTrip({ getConfig, postConfig }: IProps) {
  const navigate = useNavigate();

  const { id: tripId } = useParams();

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [status, setStatus] = useState('');
  
  const [isSaving, setIsSaving] = useState(false);

  const { data: tripRecord, isLoading } = useFetchAdminBooking(getConfig, tripId ?? '');

  useEffect(() => {
    if (tripRecord) {
      setStartDate(dateFromISOString(tripRecord.startDate))
      setEndDate(dateFromISOString(tripRecord.endDate))
      setStatus(toProperCase(tripRecord.status ?? 'Imported'));
      
    }
  }, [tripRecord]);

  if (!tripRecord) {
    return <DashboardLayout loading />;
  }

  const handleBack = () => {
    navigate(ROUTER_URLS.admin.bookings.index);
  }

  const handleSubmit = (): void => {
    setIsSaving(true);
    const params = {
      trip: {
        start_date: startDate ? startDate.toISOString() : '',
        end_date: endDate ? endDate.toISOString() : '',
        status: status.toLowerCase(),
      }
    }
    axios.put(`${API_V3_ALLIGATOR_URLS.admin.bookings.update}${tripId ?? ''}`, params, postConfig).then(() => {
      navigate(ROUTER_URLS.admin.bookings.index);
    }).catch(() => {
      setIsSaving(false);
    });
  };

  const handleStatusChange = (event: SelectChangeEvent) => {
    setStatus(event.target.value);
  };


  return (
    <DashboardLayout loading={isLoading || isSaving}>
      <Container component={Stack} maxWidth="lg" my={6}>
        <Typography component="h1" variant="h5" my={3}>
          Edit Trip Details
        </Typography>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Box sx={{ my: 3 }}>
            <DatePicker
              label="Start Date"
              value={startDate}
              onChange={(newValue) => {
                updateTimeToCurrent(newValue)
                setStartDate(newValue)
              }}
              sx={{ width: '100%' }}
            />
          </Box>
          <Box sx={{ my: 3 }}>
            <DatePicker
              label="End Date"
              value={endDate}
              onChange={(newValue) => {
                updateTimeToCurrent(newValue)
                setEndDate(newValue)
              }}
              sx={{ width: '100%' }}
            />
          </Box>
        </LocalizationProvider>
        <Box sx={{ my: 3 }}>
          <FormControl fullWidth>
            <InputLabel id="booking-status-label">Status</InputLabel>
            <Select
              labelId="status-label"
              id="status-select"
              value={status}
              label="Status"
              onChange={handleStatusChange}
              fullWidth
            >
              { BOOKING_STATUSES.map((status: string) => (
                <MenuItem key={status} value={status}>{status}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box display='flex' flexDirection='row' justifyContent='flex-end'>
          <Button variant="outlined" onClick={handleBack}>Cancel</Button>
          <Button variant="contained" onClick={handleSubmit} sx={{ mx: 2 }}>Save</Button>
        </Box>
      </Container>
    </DashboardLayout>  
  );
}

export default withCommonTools(AdminEditTrip);
