import type { UploadedFileInterface } from "../components/atoms/ListingFileUploadInput/ListingFileUploadInput";

export const listingStepOneValid = (title: string): boolean => {
  return title.length > 0;
};

export const listingStepTwoValid = (latititude: string, longitude: string): boolean => {
  return latititude.length > 0 && longitude.length > 0;
};

export const listingStepThreeValid = (amenities: string[], gearRequirements: string[], description: string): boolean => {
  return amenities.join('').length > 1 && gearRequirements.join('').length > 1 && description.length > 1;
};

export const listingStepFourValid = (price: string): boolean => {
  return price.length > 0;
};

export const listingStepFiveValid = (photos: UploadedFileInterface[]): boolean => {
  return photos.length > 0;
};

export const listingStepSixValid = (): boolean => {
  return true;
};