import { useQuery } from "react-query";
import { fetchById, fetchList } from "../api/requests";
import { API_V3_ALLIGATOR_URLS } from "../constants/api-urls";
import type { InvoiceType } from "../types/invoice";


export const useFetchInvoice = (configHeaders: { headers: { Authorization: string } }, invoiceId?: string) => {
  const config = {
    keepPreviousData: false,
    refetchOnWindowFocus: false,
    enabled: true,
  };
  return useQuery<InvoiceType>(
    ['Invoices', invoiceId],
    async () => await fetchById(API_V3_ALLIGATOR_URLS.invoice.show, invoiceId, configHeaders),
    config,
  );
};

export const useFetchInvoices = (configHeaders: { headers: { Authorization: string } }) => {
  return useQuery<InvoiceType[]>(
    ['Invoices'],
    async () => await fetchList(API_V3_ALLIGATOR_URLS.invoice.list, configHeaders),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );
};

export const useFetchInvoicesEnabled = (configHeaders: { headers: { Authorization: string } }) => {
  return useQuery<{ hostConnectId: string }>(
    ['InvoicesEnabled'],
    async () => await fetchList(API_V3_ALLIGATOR_URLS.invoice.enabled, configHeaders),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );
};

export const useFetchAdminInvoice = (configHeaders: { headers: { Authorization: string } }, invoiceId?: string) => {
  const config = {
    keepPreviousData: false,
    refetchOnWindowFocus: false,
    enabled: true,
  };
  return useQuery<InvoiceType>(
    ['AdminInvoice', invoiceId],
    async () => await fetchById(API_V3_ALLIGATOR_URLS.invoice.show, invoiceId, configHeaders),
    config,
  );
};

export const useFetchAdminInvoices = (configHeaders: { headers: { Authorization: string } }) => {
  return useQuery<InvoiceType[]>(
    ['AdminInvoice'],
    async () => await fetchList(API_V3_ALLIGATOR_URLS.invoice.list, configHeaders),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );
};
