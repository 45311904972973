import React from 'react';
import { Box, Button, Collapse, Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useMediaQuery, useTheme } from '@mui/material';
import type { BookingDetailType } from '../../../types/booking';
import PaperWithHeader from '../PaperWithHeader/PaperWithHeader';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import PayoutSchedule from './PayoutSchedule';
import { differenceInCalendarDays, parseISO } from 'date-fns';
import StripeConnectButton from '../StripeConnectButton/StripeConnectButton';

interface IProps {
  booking: BookingDetailType;
  confirmBooking: () => void;
  denyBooking: () => void;
  handleStripeClick: () => void;
  hasStripeConnected: boolean;
  userId?: string;
  usageFee: number;
};

interface FeeRow {
  desc: string;
  qty: number;
  unit: number;
  price: number;
}

const ccyFormat = (num: number) => {
  return `$${num.toFixed(2)}`;
};

const mapFeeDetails = (booking: BookingDetailType): FeeRow[] => {
  const endDate = parseISO(booking.endDate);
  const startDate = parseISO(booking.startDate);
  const numberOfDays = differenceInCalendarDays(endDate, startDate) + 1;
  const baseDescription = `Booking for ${numberOfDays} days and ${booking.numberOfGuests} hunters`;

  const additionalItems = booking.purchaseDetails?.lineItems?.map((fee) => {
    // if (fee.subTotal === 0) return undefined;

    const unit = fee.subTotal > 0 ? fee.subTotal / 100 : 0;
    const price = fee.total > 0 ? (fee.total / 100) : 0;
    return {
      desc: fee.description,
      qty: fee.quantity,
      unit,
      price,
    }
  }).filter((fee: any) => Boolean(fee)) ?? [] as FeeRow[];

  if (additionalItems?.[0]) {
    additionalItems[0].desc = baseDescription;
  }

  return additionalItems;
};



function BookingCardActions({
  booking,
  confirmBooking,
  denyBooking,
  firstPaymentCompleted,
  hasStripeConnected,
  userId,
}: {
  booking: BookingDetailType;
  confirmBooking: () => void;
  denyBooking: () => void;
  firstPaymentCompleted: boolean;
  hasStripeConnected: boolean;
  userId?: string;
}) {
  if (!hasStripeConnected) {
    return (
      <>
        <Typography variant="body1" align='center' sx={{ width: '80%', marginY: 2 }}>One last step! Before you can accept this booking you need to create a Stripe account so we can pay you.</Typography>
        <StripeConnectButton connectAccountSetup={hasStripeConnected} />
      </> 
    )
  }

  if (['requested'].includes(booking.status.toLowerCase())) {
    return (
      <>
        <Button variant='contained' color='secondary' sx={{ borderRadius: '6px', marginY: 2, width: '80%' }} onClick={confirmBooking} disabled={!booking.canBeAccepted}>Approve</Button>
        <Button variant='outlined' color='error' sx={{ borderRadius: '6px', marginY: 2, width: '80%' }} onClick={denyBooking}>Deny</Button>
        <Typography variant="body1" align='center' sx={{ width: '80%' }}>Reminder: Let your hunter know any additional information about this booking prior to approving.</Typography>
      </>
    )
  }

  if (firstPaymentCompleted) {
    return (
      <>
        <PayoutSchedule booking={booking} />
        <Typography variant="body1" align='center' sx={{ width: '80%' }}>Reminder: Let your hunter know any additional information about this booking prior to approving.</Typography>
      </>
    )
  }

  return (
    <>
      <Typography variant="body1" align='center' sx={{ width: '80%' }}>Reminder: Let your hunter know any additional information about this booking prior to approving.</Typography>
    </>
  )
}

function MobileRow({ row }: { row: FeeRow }) {
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'none' } }}>
        <TableCell sx={{ paddingX: 0 }}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => { setOpen(!open) }}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.desc}
        </TableCell>
        <TableCell align="right">{ccyFormat(row.price)}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ padding: 0 }} colSpan={3}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ marginX: 2, marginBottom: 3 }}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Qty</TableCell>
                    <TableCell align="right">Unit Cost</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      {row.qty}
                    </TableCell>
                    <TableCell align="right">{ccyFormat(row.unit)}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

function BookingFeeDetailsCard({ booking, confirmBooking, denyBooking, hasStripeConnected, userId, usageFee }: IProps) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  const taxRate = usageFee / 100.0;

  const fees = mapFeeDetails(booking);
  const invoiceSubtotal = booking.purchaseDetails?.subTotal ? booking.purchaseDetails.subTotal / 100 : 0;
  const invoiceTotal = booking.purchaseDetails?.recipientTotal ? booking.purchaseDetails.recipientTotal / 100 : 0;
  const invoiceTaxes = invoiceSubtotal - invoiceTotal;
  
  const firstPaymentCompleted = booking.userPayments?.length > 0;

  const deskTopTable = (
    <TableContainer>
      <Table size='medium' sx={{ width: '100%' }} aria-label="spanning table">
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: 600 }} align="center" colSpan={3}>
              Details
            </TableCell>
            <TableCell sx={{ fontWeight: 600 }} align="right">Price</TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ fontWeight: 600 }}>Description</TableCell>
            <TableCell sx={{ fontWeight: 600 }} align="right">Qty.</TableCell>
            <TableCell sx={{ fontWeight: 600 }} align="right">Unit Cost</TableCell>
            <TableCell sx={{ fontWeight: 600 }} align="right">Item Total</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {fees.map((row) => (
            <TableRow key={row.desc}>
              <TableCell>{row.desc}</TableCell>
              <TableCell align="right">{row.qty}</TableCell>
              <TableCell align="right">{ccyFormat(row.unit)}</TableCell>
              <TableCell sx={{ fontWeight: 600 }} align="right">{ccyFormat(row.price)}</TableCell>
            </TableRow>
          ))}
          <TableRow>
            <TableCell rowSpan={3} />
            <TableCell colSpan={2}>Subtotal</TableCell>
            <TableCell sx={{ fontWeight: 600 }} align="right">{ccyFormat(invoiceSubtotal)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Outfitter Fee</TableCell>
            <TableCell align="right">{`${(taxRate * 100).toFixed(0)}%`}</TableCell>
            <TableCell sx={{ fontWeight: 600 }} align="right">{ccyFormat(invoiceTaxes)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={2}>Total</TableCell>
            <TableCell sx={{ fontWeight: 600 }} align="right">{ccyFormat(invoiceTotal)}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );

  const mobileTable = (
    <TableContainer>
      <Table size='medium' sx={{ width: '100%' }} aria-label="spanning table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell sx={{ fontWeight: 600 }}>Description</TableCell>
            <TableCell sx={{ fontWeight: 600 }} align="right">Item Total</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          
          {fees.map((row) => { return <MobileRow key={row.desc} row={row} /> })}
          <TableRow>
            <TableCell />
            <TableCell>Subtotal</TableCell>
            <TableCell align="right">{ccyFormat(invoiceSubtotal)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell />
            <TableCell>Outfitter Fee ({`${(taxRate * 100).toFixed(0)}%`})</TableCell>
            <TableCell align="right">{ccyFormat(invoiceTaxes)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell />
            <TableCell sx={{ fontWeight: 600 }}>Total</TableCell>
            <TableCell sx={{ fontWeight: 600 }} align="right">{ccyFormat(invoiceTotal)}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );

  return (
    <PaperWithHeader headerTitle='Fee Details'>
      <Grid container direction='row' padding={3} spacing={3}>
        <Grid item xs={12} lg={12}>
          { !matches ? mobileTable : deskTopTable }
        </Grid>
        <Grid item xs={12} lg={12}>
          <Box display='flex' flexDirection='column' justifyContent='center' height='100%' alignContent='center' alignItems='center'>
            <BookingCardActions confirmBooking={confirmBooking} denyBooking={denyBooking} booking={booking} firstPaymentCompleted={firstPaymentCompleted} hasStripeConnected={hasStripeConnected} />
          </Box>
        </Grid>
      </Grid>
    </PaperWithHeader>
  );
}

export default BookingFeeDetailsCard;