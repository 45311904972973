import { withCommonTools } from '../../../components/compounds/CommonWrapper/withCommonTools';
import type { MouseEvent } from 'react';
import type { WrappedProps } from '../../../components/compounds/CommonWrapper/withCommonTools';
import DashboardLayout from '../../../components/layouts/Dashboard/Dashboard';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { useFetchAdminBooking } from '../../../hooks/fetchBooking';
import { Box, IconButton, List, Grid, ListItem, ListItemText, Stack, Typography, Table, TableRow, TableHead, TableCell, TableBody, TableContainer, Paper, Button } from '@mui/material';
import RecordDetailHeader from '../../../components/atoms/RecordDetailHeader/RecordDetailHeader';
import RecordDetailAttribute from '../../../components/atoms/RecordDetailAttribute/RecordDetailAttribute';
import { displayPhoneNumber } from '../../../utils/phoneNumber';
import { formatDateString } from '../../../utils/dates';
import { camelToProper } from '../../../utils/strings';
import EditIcon from '@mui/icons-material/Edit';
import { ROUTER_URLS } from '../../../constants/router-urls';
import axios from 'axios';
import { API_V3_ALLIGATOR_URLS } from '../../../constants/api-urls';
// import { addDays, endOfDay, isBefore, isSameDay, isWithinInterval, parseISO, startOfDay, subDays } from 'date-fns';

interface IProps extends WrappedProps {};

function AdminDebugBooking({ getConfig, postConfig }: IProps) {
  const { id: bookingId } = useParams();
  const navigate = useNavigate();
  const { data: bookingRecord, isLoading, error } = useFetchAdminBooking(getConfig, bookingId ?? '');

  if (error) {
    console.log(error)
  }

  if (!bookingRecord) {
    return <DashboardLayout loading />;
  }

  const navigateToEditPayment = (e: MouseEvent<HTMLElement>, id: number) => {
    e.stopPropagation();
    const path = generatePath(ROUTER_URLS.admin.payments.edit, { id });
    navigate(path);
  };

  const navigateToEditPayout = (e: MouseEvent<HTMLElement>, id: number) => {
    e.stopPropagation();
    const path = generatePath(ROUTER_URLS.admin.payouts.edit, { id });
    navigate(path);
  };

  const deleteTrip = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    if (bookingId) {
      axios.delete(`${API_V3_ALLIGATOR_URLS.admin.bookings.delete}/${bookingId}`, postConfig).then(() => {
        const path = generatePath(ROUTER_URLS.admin.bookings.index);
        navigate(path);
      }).catch((e) => {
        console.log(e);
      })
    }
  };

  return (
    <DashboardLayout loading={isLoading}>
      <Stack>
        <Typography variant='h4' sx={{ marginX: 5, paddingTop: 5 }}>
          Debug Booking - Status: {camelToProper(bookingRecord.status)}
        </Typography>

        <RecordDetailHeader
          title="Contact Details"
          description=""
        />
        <Box sx={{ marginX: 5 }}>
          <RecordDetailAttribute attribute="Listing Name" value={bookingRecord.listingName} />
          <RecordDetailAttribute attribute="Outfitter Name" value={bookingRecord.host.name} />
          <RecordDetailAttribute attribute="Outfitter Email" value={bookingRecord.host.email} />
          <RecordDetailAttribute attribute="User Name" value={bookingRecord.user.name} />
          <RecordDetailAttribute attribute="User Email" value={bookingRecord.user.email} />
          <RecordDetailAttribute attribute="User Phone #" value={displayPhoneNumber(bookingRecord.user.phoneNumber)} />
        </Box>

        <RecordDetailHeader
          title="Trip Details"
          description=""
        />
        <Box sx={{ marginX: 5 }}>
          <RecordDetailAttribute attribute="Start Date" value={formatDateString(bookingRecord.startDate)} />
          <RecordDetailAttribute attribute="End Date" value={formatDateString(bookingRecord.endDate)} />
          <RecordDetailAttribute attribute="Number of Hunters" value={bookingRecord.numberOfGuests.toString()} />
          <RecordDetailAttribute attribute="Address Line 1" value={bookingRecord.address.line_one} />
          <RecordDetailAttribute attribute="Address Line 2" value={bookingRecord.address.line_two} />
          <RecordDetailAttribute attribute="City" value={bookingRecord.address.city} />
          <RecordDetailAttribute attribute="State" value={bookingRecord.address.state} />
          <RecordDetailAttribute attribute="Zip Code" value={bookingRecord.address.zip_code} />
          <Button variant="contained" href={`https://www.venku.com/user/trips/${bookingRecord.slug}`} target="_blank">
            View User Trip Card
          </Button>
        </Box>

        <RecordDetailHeader
          title="Cost Details"
          description=""
        />
        <Box sx={{ marginX: 5 }}>
          <RecordDetailAttribute attribute="Subtotal" value={`$${(bookingRecord.purchaseDetails.subTotal / 100).toFixed(2)}`} />
          <RecordDetailAttribute attribute="Outfitter Total" value={`$${(bookingRecord.purchaseDetails.recipientTotal / 100).toFixed(2)}`} />
          <RecordDetailAttribute attribute="User Total" value={`$${(bookingRecord.purchaseDetails.payerTotal / 100).toFixed(2)}`} />
          <RecordDetailAttribute attribute="Total" value={`$${(bookingRecord.purchaseDetails.total / 100).toFixed(2)}`} />
          <RecordDetailAttribute attribute="Pay Later" value={bookingRecord.payLater ? 'Y' : 'N'} />
          {bookingRecord.purchaseDetails?.lineItems && bookingRecord.purchaseDetails.lineItems.length > 0 && <Box>
            <Typography variant='body1' sx={{ fontWeight: 600 }}>Line Items</Typography>
            <TableContainer component={Paper} sx={{ maxWidth: 600, marginY: 2 }}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Description</TableCell>
                    <TableCell align="right">Qty</TableCell>
                    <TableCell align="right">Recipient Total</TableCell>
                    <TableCell align="right">Total</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {bookingRecord.purchaseDetails.lineItems.map((item) => (
                    <TableRow
                      key={item.id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {item.description}
                      </TableCell>
                      <TableCell align="right">{item.quantity}</TableCell>
                      <TableCell align="right">${(item.recipientTotal/100).toFixed(2)}</TableCell>
                      <TableCell align="right">${(item.total/100).toFixed(2)}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>}
        </Box>


        <Grid container sx={{ marginBottom: 10 }}>
          <Grid item xs={12} lg={6}>
            <RecordDetailHeader
              title="User Payment Details"
              description=""
            />
            <Box sx={{ marginX: 5 }}>  
              <List sx={{ listStyle: "decimal", paddingLeft: 2 }}>
                {bookingRecord.userPayments.map((payment) => {
                  const text = `${formatDateString(payment.paymentDate)} - $${(payment.amount / 100).toFixed(2) ?? ''} - ${payment.status} - ${payment.description}`
                  return (
                    <ListItem
                      key={payment.id}
                      sx={{ display: "list-item" }}
                      secondaryAction={
                        <IconButton edge="end" aria-label="edit" onClick={(e) => { navigateToEditPayment(e, payment.id) }}>
                          <EditIcon />
                        </IconButton>
                      }
                    >
                      <ListItemText primary={text} />
                    </ListItem>)
                })}
              </List>
            </Box>  
          </Grid>
          <Grid item xs={12} lg={6}>
            <RecordDetailHeader
              title="Refund Details"
              description=""
            />
            <Box sx={{ marginX: 5 }}>  
              <List sx={{ listStyle: "decimal", paddingLeft: 2 }}>
                {bookingRecord.userRefunds.map((refund) => {
                  const text = `${formatDateString(refund.refundDate)} - $${(refund.amount / 100).toFixed(2) ?? ''} - ${refund.status} - ${refund.description}`
                  return (
                    <ListItem
                      key={refund.id}
                      sx={{ display: "list-item" }}
                    >
                      <ListItemText primary={text} />
                    </ListItem>)
                })}
              </List>
            </Box>  
          </Grid>
          <Grid item xs={12} lg={6}>
            <RecordDetailHeader
              title="Outfitter Payout Details"
              description=""
            />
            <Box sx={{ marginX: 5 }}>
              <List sx={{ listStyle: "decimal", pl: 4 }}>
                {bookingRecord.payouts.sort((a, b) => a.payoutNumber - b.payoutNumber).map((payout) => {
                  return (
                    <ListItem
                      key={payout.id}
                      sx={{ display: "list-item" }}
                      secondaryAction={
                        <IconButton edge="end" aria-label="edit" onClick={(e) => { navigateToEditPayout(e, payout.id) }}>
                          <EditIcon />
                        </IconButton>
                      }
                    >
                      <ListItemText primary={`${formatDateString(payout.payoutDate)} - $${(payout.amount / 100).toFixed(2)} - ${payout.status} - ${payout.description}`} />
                    </ListItem>)
                })}
              </List>
            </Box>
          </Grid>
          <Grid item xs={12} lg={6}>
            <RecordDetailHeader
              title="Reversal Details"
              description=""
            />
            <Box sx={{ marginX: 5 }}>  
              <List sx={{ listStyle: "decimal", paddingLeft: 2 }}>
                {bookingRecord.reversals.map((reversal) => {
                  const text = `${formatDateString(reversal.reversalDate)} - $${(reversal.amount / 100).toFixed(2) ?? ''} - ${reversal.status} - ${reversal.description}`
                  return (
                    <ListItem
                      key={reversal.id}
                      sx={{ display: "list-item" }}
                    >
                      <ListItemText primary={text} />
                    </ListItem>)
                })}
              </List>
            </Box>  
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', paddingY: 2, marginX: 5, marginTop: 5 }}>
              <Grid container direction='row'>
                <Grid item lg={12} xs={12}>
                  <Typography variant="h5" marginY={1}>DANGER - Unreversable admin functions</Typography>
                  <Typography variant="body1">Please confirm you know the result of each action prior to clicking</Typography>
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ marginX: 5 }}>
              <Grid container direction='row' marginY={2}>
                <Grid item xs={12} lg={6}>
                  <Typography variant='body1'>Deletes the booking and related payments/payouts. Does not issue refunds or reversals.</Typography>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Button variant='contained' color='error' onClick={deleteTrip}>
                    Delete
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Stack>
    </DashboardLayout>
  )
};

export default withCommonTools(AdminDebugBooking);