
import axios from "axios";
import { useQuery } from "react-query";
import { fetchById, fetchList } from "../api/requests";
import { API_V3_ALLIGATOR_URLS } from "../constants/api-urls";
import type { ListingDetailType, ListingResultType } from "../types/listing";
import type { PagedResultsType } from "../types/pagedResults";
import type { GridSortItem } from "@mui/x-data-grid";

interface ListingSearchParamsType {
  listing: {
    name: string;
    host_name: string;
    host_id: string | null;
    address_state: string | null;
    min_price: number | null;
    max_price: number | null;
    sellable: boolean | null;
  }
  page: number;
  sort: GridSortItem
}

export const useFetchListing = (configHeaders: { headers: { Authorization: string } }, listingId?: string) => {
  const config = {
    keepPreviousData: false,
    refetchOnWindowFocus: false,
    enabled: true,
  };
  return useQuery<ListingDetailType>(
    ['ListingInfo', listingId],
    async () => await fetchById(API_V3_ALLIGATOR_URLS.listing.show, listingId, configHeaders),
    config,
  );
};

export const useFetchListings = (configHeaders: { headers: { Authorization: string } }) => {
  return useQuery<ListingResultType[]>(
    ['ListingsInfo'],
    async () => await fetchList(API_V3_ALLIGATOR_URLS.listing.list, configHeaders),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );
};

export const useFetchAdminListings = (config: { headers: { Authorization: string } }, params: ListingSearchParamsType) => {
  return useQuery<{listings: ListingDetailType[], page: PagedResultsType}>(
    ['AdminBookingsInfo', params.page, params.sort?.field, params.sort?.sort],
    async () => {
      const response = await axios.post(API_V3_ALLIGATOR_URLS.admin.listings.search, params, config);

      if (response.status !== 200) {
        if (response.status === 401) {
          throw new Error('error');
        }
        throw new Error('Network response was not ok');
      }
      return response.data;
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
    }
  );
};

export const useFetchAdminListing = (configHeaders: { headers: { Authorization: string } }, listingId: string) => {
  const config = {
    keepPreviousData: false,
    refetchOnWindowFocus: false,
    enabled: true,
  };
  return useQuery<ListingDetailType>(
    ['ListingInfo', listingId],
    async () => await fetchById(API_V3_ALLIGATOR_URLS.admin.listings.show, listingId, configHeaders),
    config,
  );
};