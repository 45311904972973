import * as React from 'react';
import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

interface IProps {
  itemId: number;
  displayItem: (id: number) => void;
  deleteItem: (id: number) => void;
  editItem: (id: number) => void;
}

export default function LineItemActions({ itemId, displayItem, editItem, deleteItem }: IProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const actionsOpen = Boolean(anchorEl);
  const handleActionsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleActionsClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label="options"
        aria-controls={actionsOpen ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={actionsOpen ? 'true' : undefined}
        onClick={handleActionsClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={actionsOpen}
        onClose={handleActionsClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem disabled onClick={() => { displayItem(itemId) }}>
          <ListItemText>Details</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => { deleteItem(itemId) }}>
          <ListItemIcon>
            <DeleteIcon fontSize="small" color='error' />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem>
        <MenuItem disabled onClick={() => { editItem(itemId) }}>
          <ListItemIcon>
            <EditIcon fontSize="small" color='primary' />
          </ListItemIcon>
          <ListItemText>Edit</ListItemText>
        </MenuItem>
      </Menu>
    </div>
  );
}