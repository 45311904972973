import { Button, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import axios from "axios";
import { useState } from "react";
import { API_V3_ALLIGATOR_URLS } from "../../../constants/api-urls";
import type { WrappedProps } from "../CommonWrapper/withCommonTools";
import { withCommonTools } from "../CommonWrapper/withCommonTools";

interface IProps extends WrappedProps {
  message: string;
  updateInfoPrompt: string;
};

function StripeSetupRequired({ message, updateInfoPrompt, getConfig }: IProps) {
  const [stripeLoading, setStripeLoading] = useState(false);

  const handleStripeConnectClick = () => {
    setStripeLoading(true);
    axios.post(API_V3_ALLIGATOR_URLS.stripeConnect.create, {}, getConfig).then((res) => {
      window.location.replace(res.data.stripe_link);
      setStripeLoading(false);
    }).catch((e) => {
      console.log(e);
      setStripeLoading(false);
    })
  }
  // 
  return (
    <div>
      <Grid2 container>
        <Grid2 xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
          <Typography>{message}</Typography>
        </Grid2>
        <Grid2 xs={12} sx={{ display: 'flex', justifyContent: 'center', marginY: 2 }}>
          <Button
            variant='contained'
            color='secondary'
            onClick={handleStripeConnectClick}
            disabled={stripeLoading}
          >
          { updateInfoPrompt } 
          </Button>
        </Grid2>
      </Grid2>
    </div>
  );
}

export default withCommonTools(StripeSetupRequired);