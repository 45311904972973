import React from 'react';
import { Box, List } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import LuggageIcon from '@mui/icons-material/Luggage';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import ForestIcon from '@mui/icons-material/Forest';
import StatsIcon from '@mui/icons-material/QueryStats';
import NavListItem from '../../atoms/NavListItem/NavListItem';
import ExpandableNavListItem from '../../atoms/ExpandableNavListItem/ExpandableNavListItem';
import SubNavListItem from '../../atoms/SubNavListItem/SubNavListItem';
import AssignmentIcon from '@mui/icons-material/Assignment';
import MobileScreenShareIcon from '@mui/icons-material/MobileScreenShare';
import DrawIcon from '@mui/icons-material/Draw';
import WebIcon from '@mui/icons-material/Web';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import { ROUTER_URLS } from '../../../constants/router-urls';
import { useAuthUser } from 'react-auth-kit';
import { ADMIN_USER, REFERRAL_USER, HOST_USER, HOST_AND_REFERRAL_USER, SALES_USER } from '../../../constants/settings';
import { getSessionStorageOrDefault } from '../../../utils/storage';



function DrawerLinks() {
  const auth = useAuthUser();
  const userInfo = auth();
  const accessAdmin = userInfo?.role === ADMIN_USER;
  const accessReferral = [ADMIN_USER, REFERRAL_USER, HOST_AND_REFERRAL_USER, SALES_USER].includes(userInfo?.role);
  const accessHost = [ADMIN_USER, HOST_USER, HOST_AND_REFERRAL_USER].includes(userInfo?.role);
  const accessSales = [SALES_USER].includes(userInfo?.role);
  const accessBookNow = userInfo?.accessBookNowApp;

  const routes = ROUTER_URLS;

  const impersonateUserId = getSessionStorageOrDefault('impersonateUserId') as string | undefined;

  const adminLinks = (
    <ExpandableNavListItem text="Admin" Icon={<AssignmentIcon />}>
      <SubNavListItem text="Users" path={routes.admin.users.index} />
      <SubNavListItem text="Bookings" path={routes.admin.bookings.index} />
      <SubNavListItem text="Listings" path={routes.admin.listings.index} />
      <SubNavListItem text="Payments" path={routes.admin.payments.index} />
      <SubNavListItem text="Payouts" path={routes.admin.payouts.index} />
      <SubNavListItem text="Refunds" path={routes.admin.refunds.index} />
      <SubNavListItem text="Reversals" path={routes.admin.reversals.index} />
      <SubNavListItem text="Host Contacts" path={routes.admin.contactHosts.index} />
      <SubNavListItem text="Invoices" path={routes.admin.invoices.index} disabled />
    </ExpandableNavListItem>
  );

  const salesLinks = (
    <ExpandableNavListItem text="Admin" Icon={<AssignmentIcon />}>
      <SubNavListItem text="Users" path={routes.admin.users.index} />
      <SubNavListItem text="Bookings" path={routes.admin.bookings.index} />
      <SubNavListItem text="Listings" path={routes.admin.listings.index} />
      <SubNavListItem text="Host Contacts" path={routes.admin.contactHosts.index} />
    </ExpandableNavListItem>
  )

  const referralLinks = (
    <NavListItem text="Referral Program" path={routes.referralProgram.dashboard} Icon={<MobileScreenShareIcon />} />
  );

  const bookNowLinks = (
    <NavListItem text="Hunt Pay Pages" path={routes.landings.index} Icon={<WebIcon />} />
  )

  const hostLinks = (
    <>
      <NavListItem text="Home" Icon={<HomeIcon />} path={routes.home} />
      <NavListItem text="Calendar" Icon={<CalendarMonthOutlinedIcon />} path={routes.calendar.index} />
      <NavListItem text="Open Dates" Icon={<EventAvailableIcon />} path={routes.calendar.availability} />
      <NavListItem text="Bookings" Icon={<LuggageIcon />} path={routes.bookings.index} />
      <NavListItem text="Listings" Icon={<ForestIcon />} path={routes.listings.index} />
      <NavListItem text="Recent Deposits" Icon={<StatsIcon />} path={routes.reports.recentDeposits} />
      <NavListItem text="Waivers" Icon={<DrawIcon />} path={routes.waivers.index} />
      <NavListItem text="Invoices" Icon={<RequestQuoteIcon />} path={routes.invoices.index} />
    </>
  )

  return (
    <Box overflow='auto' paddingTop={2}>
      <List>
        { accessHost && hostLinks }
        { accessBookNow && bookNowLinks }
        { accessReferral && referralLinks }
        { accessAdmin && !impersonateUserId && adminLinks }
        { accessSales && salesLinks }
      </List>
    </Box>
  );
}

export default DrawerLinks;
