import { ListingStatus } from "../types/enums";
import type { ListingAdditionalCostItemType } from "../types/listing";

export const LISTING_DROPDOWN_OPTIONS = [
  'active',
  'pending',
  'expired',
];

export const LISTING_STATUSES = [
  { id: 0, values: ['active'], label: 'Active' },
  { id: 1, values: ['pending'], label: 'Pending' },
  { id: 2, values: ['expired'], label: 'Expired' },
];

export const LISTING_PRICING_UNIT = [
  { id: 'PerDay', label: 'Per Day' },
  { id: 'PerNight', label: 'Per Night' },
  { id: 'PerExperience', label: 'Per Experience' },
  { id: 'PerLease', label: 'Per Lease' },
];

export const LISTING_SECURITY_DEPOSIT_PERCENTAGE = [
  { id: '10', label: '10%' },
  { id: '25', label: '25%' },
  { id: '50', label: '50%' },
];

export const LISTING_ADDITIONAL_COST_ITEM: ListingAdditionalCostItemType = {
  description: '',
  price: 0,
  perPerson: false,
  perDay: false,
  perNight: false,
  isOptional: false,
  canSpecifyNumber: false,
  minValue: 1,
  maxValue: 1,
};

export const LISTING_SKILL_LEVEL = [
  { id: 'all', label: 'All' },
  { id: 'beginner', label: 'Beginner'},
  { id: 'intermediate', label: 'Intermediate'},
  { id: 'advanced', label: 'Advanced'},
];

export const LISTING_CATEGORIES = [
  { id: 'biking', label: 'Biking' },
  { id: 'cabins_and_lodging', label: 'Cabins & Lodging' },
  { id: 'camping', label: 'Camping' },
  { id: 'eco_tours', label: 'Eco-Tours' },
  { id: 'equestrian', label: 'Equestrian' },
  { id: 'fishing', label: 'Fishing' },
  { id: 'foraging', label: 'Foraging' },
  { id: 'hiking', label: 'Hiking' },
  { id: 'hunting', label: 'Hunting' },
  { id: 'kayaking', label: 'Kayaking' },
  { id: 'mountain_biking', label: 'Mountain Biking' },
  { id: 'off_road', label: 'Off-Road' },
  { id: 'rv_camping', label: 'RV Camping' },
  { id: 'shooting', label: 'Shooting' },
  { id: 'wildlife_photography', label: 'Wildlife Photography' },
  { id: 'winter_sports', label: 'Winter Sports' },
];

export const LISTING_ADDITIONAL_AMENITY = {

};

export const LISTING_GEAR_ITEM = {

};

export const newListingSteps = [
  {
    id: 0,
    label: '',
    prompt: 'Begin by adding details and describing your new listing',
    optional: false,
  },
  {
    id: 1,
    label: '',
    prompt: 'Add address for this listing',
    optional: false,
  },
  {
    id: 2,
    label: '',
    prompt: 'Add details describing listing rules, ammenities, hunter skill level, and required or recommened gear',
    optional: false,
  },
  {
    id: 3,
    label: '',
    prompt: 'Set and review pricing details',
    optional: false,
  },
  {
    id: 4,
    label: '',
    prompt: 'Upload photos for your listing here!',
    optional: false,
  },
  {
    id: 5,
    label: '',
    prompt: 'Set which dates the listing is available and hunter arrival details',
    optional: false,
  },
];

export const DEFAULT_NEW_LISTING_PARAMS = {
  "categoryName": "",
  "categorySlug": "",
  "phone": "",
  "enforceAccomdationRange": true,
  "accommodateMin": 1,
  "accommodateMax": 1,
  "address": {
    "address1": "",
    "address2": "",
    "city": "",
    "state": "",
    "zip": "",
    "country": "",
    "latitude": "",
    "longitude": ""
  },
  "showMap": false,
  "showFullAddress": false,
  "availabilityRange": null,
  "availableDates": null,
  "checkIn": new Date(),
  "checkOut": new Date(),
  "price": 100,
  "pricePerPerson": false,
  "pricePerDay": false,
  "listingUnit": "perday",
  "name": "",
  "shortDescription": "",
  "description": "",
  "houseRules": "",
  "handicappedAccessible": false,
  "petFriendly": false,
  "kidFriendly": false,
  "skillLevel": "All",
  "amenities": [
    ""
  ],
  "extras": [
    ""
  ],
  "additionalCosts": [],
  "cancellationPolicy": "",
  "additionalDocuments": [],
  "images": [],
  "primaryImages": [],
  "timeZone": "",
  "languagesSpoken": null,
  "addressOption": "address",
  "keywords": null,
  "status": ListingStatus.Pending,
  "seoPageTitle": null,
  "seoPageDescription": null,
  "invasive": false,
  "acreage": null,
  "species": '',
  "subSpecies": '',
  "gameType": '',
  "lodgingType": '',
  "propertyType": '',
  "guidedType": '',
  'bucketList': '',
  'featuredList': '',
  'featuredListOrder': '',
};

export const LISTING_AMENITIES_DEFAULT_OPTIONS = [
  'Access to Private Land',
  'All Appropriate Fishing Equipment Provided',
  'All Appropriate Hunting Equipment Provided',
  'Boat', 'Camp Style Meals',
  'DIY Hunt',
  'Experienced Local Guide',
  'Feeders or Food Plots',
  'Fishing Gear',
  'Full Kitchen',
  'Fully Guided',
  'Ground Blind',
  'Group Lodging',
  'Layout Blind',
  'Lodging',
  'Lodging Located Nearby',
  'Managed Land',
  'Meals',
  'Private Lodging',
  'Semi-Guided',
  'Spike Camp',
  'Thermal Equipment',
  'Transportation around the hunting property (ATV/Horseback/Side-by-side)',
  'Transportation to the Stand',
  'Transportation to/from the Airport',
  'Upland Dogs',
  'Waterfowl Retriever Dog',
  'Wifi'
];

export const LISTING_GEAR_REQUIREMENT_OPTIONS = [
  'Backpack/Daypack',
  'Cooler',
  'Food / Drinks / Snacks',
  'Headlamp/Flashlight',
  'Hunter Orange',
  'Knee Boots',
  'Overnight Supplies',
  'Personal Gear',
  'Valid State Hunting License/Tags',
  'Waders',
  'Weapon and Ammo',
  'Weather Appropriate Hunting Attire'
];

export const LISTING_PROPERTY_TYPE_OPTIONS = [
  { id: 'public', label: 'Public' },
  { id: 'private', label: 'Private' },
  { id: 'both', label: 'Both' },
];

export const LISTING_GUIDED_TYPE_OPTIONS = [
  { id: 'guided', label: 'Guided' },
  { id: 'semiGuided', label: 'Semi-Guided' },
  { id: 'selfGuided', label: 'Self-Guided/DIY' },
  { id: 'any', label: 'Any' },
];

export const LISTING_LODGING_TYPE_OPTIONS = [
  { id: 'lodge', label: 'Lodge' },
  { id: 'cabin', label: 'Cabin' },
  { id: 'trailer', label: 'Trailer' },
  { id: 'camper', label: 'Camper/RV' },
  { id: 'hotel', label: 'Hotel' },
  { id: 'dropCamp', label: 'Drop Camp' },
  { id: 'tent', label: 'Tent' },
  { id: 'none', label: 'None' },
];