export const ROUTER_URLS = {
  admin: {
    users: {
      index: '/admin/users',
      show: '/admin/user/:id',
      edit: '/admin/user/:id/edit',
      editAccount: '/admin/user/:id/edit/account',
      editEmergencyContact: '/admin/user/:id/edit/emergencyContact',
      editPassword: '/admin/user/:id/edit/changePassword',
      editUsageFees: '/admin/user/:id/edit/usageFees',
      new: '/admin/user/new',
    },
    bookings: {
      index: '/admin/bookings',
      debug: '/admin/booking/:id/debug',
      edit: '/admin/booking/:id/edit',
      offline: '/admin/bookings/new',
      confirmOffline: '/admin/bookings/:id/confirm',
    },
    listings: {
      index: '/admin/listings',
      new: '/admin/listings/new',
      show: '/admin/listings/:id',
      edit: '/admin/listings/:id/edit',
      editAddress: '/admin/listings/:id/editAddress',
      editDetails: '/admin/listings/:id/editDetails',
      editPricing: '/admin/listings/:id/editPricing',
      editAdditionalCharges: '/admin/listings/:id/editAdditionalCharges/:additionalChargeId',
      editPhotos: '/admin/listings/:id/editPhotos',
      editAvailability: '/admin/listings/:id/editAvailability',
      editAdminFields: '/admin/listings/:id/editAdminFields',
    },
    payments: {
      index: '/admin/payments',
      edit: '/admin/payments/:id/edit',
    },
    payouts: {
      index: '/admin/payouts',
      edit: '/admin/payouts/:id/edit',
    },
    refunds: {
      index: '/admin/refunds',
      new: '/admin/refunds/:id/edit',
    },
    reversals: {
      index: '/admin/reversals',
      new: '/admin/reversals/:id/edit',
    },
    contactHosts: {
      index: '/admin/contactHosts',
    },
    invoices: {
      index: '/admin/invoices',
      show: '/admin/invoices/:id',
    }
  },
  auth: {
    login: '/login',
  },
  bookings: {
    index: '/bookings',
    show: '/bookings/:id',
    confirm: '/bookings/:id/confirm',
    new: '/bookings/new',
    edit: '/bookings/:id/edit',
  },
  listings: {
    index: '/listings',
    show: '/listings/:id',
    new: '/listings/new',
    edit: '/listings/:id/edit',
    editAddress: '/listings/:id/edit/address',
    editAdditionalDetails: '/listings/:id/edit/additionalDetails',
    editPricing: '/listings/:id/edit/pricing',
    editFileUploads: '/listings/:id/edit/fileUpload',
    editAvailability: '/listings/:id/edit/availability',
  },
  landings: {
    index: '/landings',
    edit: '/landings/:id/edit'
  },
  messages: {
    index: '/messages',
    show: '/messages/:id',
  },
  user: {
    editAccount: '/profile/edit/account',
    editEmergencyContact: '/profile/edit/emergencyContact',
    editPassword: '/profile/edit/changePassword',
    edit: '/profile/edit',
    profile: '/profile',
    editProfilePicture: '/profile/edit/picture',
    // added this here so the url is a tiny bit tougher to find. Feel like it belongs in auth though
    forgotPassword: '/profile/forgotPassword',
    resetPassword: '/profile/resetPassword',
    verifyEmail: '/profile/verifyemail',
    connectClose: '/profile/connect-close',
    connectRefresh: '/profile/connect-refresh',
  },
  reports: {
    recentDeposits: '/reports/recentDeposits',
    listingViews: '/reports/listingViews',
  },
  referralProgram: {
    dashboard: '/referralProgram/dashboard',
  },
  calendar: {
    index: '/calendar',
    availability: '/calendar/availability',
  },
  waivers: {
    index: '/waivers',
    waiverUpload: '/waivers/upload',
    show: '/waivers/:id',
    templates: '/waivers/templates',
  },
  invoices: {
    index: '/invoices',
    show: '/invoices/:id',
    new: '/invoices/new',
  },
  dashboard: '/dashboard',
  home: '/',
} 