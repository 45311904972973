import React from 'react';
import { withCommonTools } from '../../../components/compounds/CommonWrapper/withCommonTools';
import type { WrappedProps } from '../../../components/compounds/CommonWrapper/withCommonTools';

import Dashboard from '../../../components/layouts/Dashboard/Dashboard';


interface IProps extends WrappedProps {};

function AdminHostContacts({ getConfig }: IProps) {
  
  return (
    <Dashboard>
      
    </Dashboard>
  );
}

export default withCommonTools(AdminHostContacts);